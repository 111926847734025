import React, { useState } from "react";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import GoodPractisespdf from "../static/GoodPractices.pdf";
import HiringRetentionPromotion from "../static/HiringRetentionPromotion.pdf";
import Teaching from "../static/GoodPractices.pdf";
import HarrassmentStaffStudentRelationships from "../static/HarrassmentStaffStudentRelationships.pdf";
import Caregivers from "../static/Caregivers.pdf";
import Journals from "../static/Journals.pdf";
import ConferencesEvents from "../static/ConferencesEvents.pdf";
import LearnedSocieties from "../static/LearnedSocieties.pdf";
import ResearchProjects from "../static/ResearchProjects.pdf";

// import { useInView } from "react-intersection-observer";
import Layout from "../components/layout";
import under_construction from "../images/under_construction.jpg";
import sealImage from "../images/seal.webp";

// https://www.bram.us/2020/01/10/smooth-scrolling-sticky-scrollspy-navigation/

import "./practices.css";
import Signatory from "../components/signatory";

const PracticesPage = () => {
  const [showProcedure, setShowProcedure] = useState(false);
  const [open, setOpen] = useState({
    departments: false,
    journals: false,
    societies: false,
    groups: false,
  });
  const toggleShowProcedure = () => {
    setShowProcedure(!showProcedure);
  };

  const data = useStaticQuery(graphql`
    {
      allSignatory {
        edges {
          node {
            entity
            name
            link
            exceptions
          }
        }
      }
      sealImage: file(relativePath: { eq: "seal.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const signatories = data.allSignatory.edges.map(
    (signatory) => signatory.node
  );
  const departmentElems = signatories
    .filter((s) => s.entity === "department")
    .map(Signatory);
  const journalElems = signatories
    .filter((s) => s.entity === "journal")
    .map(Signatory);
  const societyElems = signatories
    .filter((s) => s.entity === "society")
    .map(Signatory);
  const leaderElems = signatories
    .filter((s) => s.entity === "leader")
    .map(Signatory);

  const toggleUl = (name) => {
    console.log({ name, open });
    setOpen({
      ...open,
      [name]: !open[name],
    });
  };

  return (
    <>
      <Layout>
        <div className="practices-page">
          <div className="description">
            <h1>Good Practices for Improving Representation</h1>
            <p>
              The Demographics in Philosophy project proposes the following
              guidelines in inclusion with influence to promote, within the
              philosophical community, practices that will, if adopted, create a
              more welcoming and inclusive environment for all -including women,
              people of color, disabled people, non-native English speakers,
              first-generation college students, those with serious economic
              needs or from developing countries, LBGTQ+ people, and people with
              political or religious views that are under-represented in the
              discipline, amongst others. We hope these suggestions will act as
              a starting point for improving the conditions for everyone in our
              discipline. The following guidelines promote equal opportunities
              for under-represented groups in philosophy and support and
              encourage academic excellence of all kinds.
            </p>
            <p>
              Click{" "}
              <a href={GoodPractisespdf} target="_blank">
                here
              </a>
            </p>
          </div>
          <div className="medal-tab">
            <div className="seal">
              <img src={sealImage} alt="seal" />
            </div>
            <h2 className="heading">Good Practices Documents</h2>
            <h3
              className="practices-section-header"
              onClick={() => toggleUl("departments")}
            >
              Departments
            </h3>
            <ul
              className=""
              style={{ display: `${open.departments ? "block" : "none"}` }}
            >
              <li>
                <a href={HiringRetentionPromotion} target="_blank">
                  Hiring, Retention, and Promotion
                </a>
              </li>
              <li>
                <a href={Teaching} target="_blank">
                  Teaching
                </a>
              </li>
              <li>
                <a href={HarrassmentStaffStudentRelationships} target="_blank">
                  Harrassment and Staff-Student Relationships
                </a>
              </li>
              <li>
                <a href={Caregivers} target="_blank">
                  Caregivers
                </a>
              </li>
            </ul>
            <h3
              className="practices-section-header"
              onClick={() => toggleUl("journals")}
            >
              Journals
            </h3>
            <ul
              className=""
              style={{ display: `${open.journals ? "block" : "none"}` }}
            >
              <li>
                <a href={Journals} target="_blank">
                  Journals
                </a>
              </li>
            </ul>
            <h3
              className="practices-section-header"
              onClick={() => toggleUl("societies")}
            >
              Learned Societies
            </h3>
            <ul
              className=""
              style={{ display: `${open.societies ? "block" : "none"}` }}
            >
              <li>
                <a href={ConferencesEvents} target="_blank">
                  Conferences and Events
                </a>
              </li>
              <li>
                <a href={LearnedSocieties} target="_blank">
                  Learned Societies
                </a>
              </li>
            </ul>
            <h3
              className="practices-section-header"
              onClick={() => toggleUl("groups")}
            >
              Research Groups
            </h3>
            <ul
              className=""
              style={{ display: `${open.groups ? "block" : "none"}` }}
            >
              <li>
                <a href={ResearchProjects} target="_blank">
                  Research Projects
                </a>
              </li>
              <li>
                <a href={HiringRetentionPromotion} target="_blank">
                  Hiring, Retention, and Promotion
                </a>
              </li>
              <li>
                <a href={Caregivers} target="_blank">
                  Caregivers
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PracticesPage;
